@import './vars.scss';

table {
	margin: 10px 0;
    border-spacing: 0;
	border-radius: 12px;
	overflow: hidden;
	font-family: $font-1;
	// font-size: 15px;
	color: $c5;
	width: 100%;
	// border: 2px solid $c2;
	box-shadow: 0px 0px 10px 5px $c1;

	thead {
		letter-spacing: 0.8px;
		// font-size: 15px;
		// color: $c5;
		font-weight: 0;
		box-shadow: 0px 0px 20px -1px rgba($c4, 0.4);
		// background-color: rgba($light-2, 0.4);
		// background-color: rgba($c3, 0.1);
	}

	tbody tr:nth-child(even) {
		background-color: rgba($c3, 0.1);
	}

	tr:last-child td{
		border-bottom: 0;
	}
	
    th, td {
      margin: 0;
      padding: 4px 10px;
      border-bottom: 1px solid rgba($light-3, 0.15);
      border-right: 1px solid rgba($light-3, 0.15);
	  
	  &:last-child {
		  border-right: 0;
	  }

	}
	
  }
