@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

$dark: #ecefef;
$dark-2: #f8fafa;
$light-1: #0a586d;
// $light-1: #77c9d4;
$light-2: #c6f5f2;
$light-3: #299097;
$mid: #a6a6af;
$background: #eef1f0; 

$c1:#dbd6cf;
$c2:#edeadf;
$c3:#f5cc5c;
$c4:#242423ff;
$c5:#5a5549;
$c6:#d1a939;

$font-1: 'Roboto', sans-serif;
$font-2: 'Noto Sans', sans-serif;
$font-3: 'Lato', sans-serif;
// $font-2: 'Montserrat', sans-serif;

$navbar-width: 320px;
