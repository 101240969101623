@import './vars.scss';

.form-group {
	margin: 20px 0;
}

.inline-element {
	display: inline-block;

	&:nth-of-type(even) {
		margin-left: 16px;
	}
}

.form-element {

	margin: 3px 0;

	&.centered > * {
		margin-left: auto;
		margin-right: auto;
	}

	&.process > *, &.process > *:hover {
		background-color: $light-2;
		box-shadow: 0px 0px 6px 0.3px rgba($light-1, 0.9);
		color: $light-1;
		border-color: $light-2;
	}
	
	.form-element-label {
		display: block;
		font-weight: 600;
		font-family: $font-1;
		padding-left: 4px;
		
		.label  {
			display: block;
			font-size: 13px;
			color: rgba($c5, 0.9);
			letter-spacing: 1px;
		}
		.validation-msg {
			// height: 20px;
			margin-top: -8px;
			display: block;
			font-size: 12px;
			color: #e63131;
			letter-spacing: 0.5px;
		}

	}

	* {
		font-family: $font-2;
		
		&:focus {
			outline: none;
		}
	}

	.max-width {
		width: 100%;
	}

	.vertical-resize {
		resize: vertical;
	}

	.bordered-input {

		padding: 7px 12px;
		border-radius: 10px;
		border: solid 2px rgba($c5, 0.3);
		// border: solid 2px rgba($c5, 0.4);
		background-color: #ffffff;
		box-shadow: 0px 0px 4px 1px $c1;
		color: $c5;
		letter-spacing: 0.5px;
		font-size: 14px;

		&:focus {
			border: solid 2px $c3;
			// outline: solid 1.5px rgba($light-3, .6);
		}

	}

	textarea {
		min-height: 100px;
	}

	button {
		cursor: pointer;
		font-family: $font-3;
		display: block;
		padding: 8px 12px;
		font-weight: bold;
		font-size: 17px;
		letter-spacing: 0.4px;
		border-radius: 10px;
		color: $c3;
		background-color: $c4;
		border: solid 3px $c5;
		box-shadow: 0px 0px 4px 0px $c4;

		&:hover {
			background-color: $c3;
			box-shadow: 0px 0px 20px -2px $c3;
			color: $c4;
			border-color: $c2;
			font-weight: bold;
		}

		&:disabled {
			background-color: transparent;
			box-shadow: 0px 0px 6px 0.3px $c3;
			color: $c4;
			border-color: transparent;
		}
	}

	.checkbox-group {

		display: flex;
		margin-top: 2px;

		.checkbox-column {
			flex: 0 0 50px;

			.checkbox {
				display: block;
				width: 24px;
				height: 24px;
				margin: auto;
				margin-top: 7px;
				padding: 3px;
				cursor: pointer;

				.checkbox-inside {
					display: none;
				}	

				&.checked {					
					.checkbox-inside {
						display: block;
						background-color: $c5;
						// box-shadow: 0px 0px 2px 0px $c3;
						width: 100%;
						height: 100%;
						border-radius: 20px;
					}
				}

				&:hover {
					border: solid 1px $c3;

					&:not(.checked) .checkbox-inside {
						display: block;
						// background-color: rgba($light-3, 0.4);
						background-color: rgba($c3, 0.4);
						// box-shadow: 0px 0px 2px 0px rgba($light-1, 0.4);
						width: 100%;
						height: 100%;
						border-radius: 4px;
					}
				}			
			}
		}

		.text-column {
			font-size: 14px;
			letter-spacing: -0.3px;
			line-height: 18px;
			margin-top: 4px;
			color: rgba($c5, 0.9);
			font-family: $font-1;
		}
	}

	

	.dropdown-field > div div {
		display: flex;
		justify-content: space-between;
	}

	.dropdown-group {
		.dropdown-field {
			cursor: pointer;
			display: inline-block;
			width: 150px;
			margin: 0;
			padding-top: 2px;
			padding-bottom: 2px;

			.dropdown-icon {
				user-select: none; 
				cursor: inherit;
				margin-right: 5px;
				font-weight: 600;
			}
		
			&:hover {
				border: solid 2px $c3;
				background-color: rgba($c3, 0.4);
				// background-color: $c3;
				// box-shadow: 0px 0px 3px 0px $c3;
				// color: $c5;
			}
		}
	
		.dropdown-options {
			display: block;
			position: absolute;
			cursor: pointer;
			width: 150px;
			padding: 0;
			overflow: hidden;

			.dropdown-option {
				padding: 2px 4px;
			
				&:not(:last-child) {
					border-bottom: solid 1px rgba($light-3, 0.4);
				}
			
				&:hover {
					background-color: rgba($c3, 0.4);
				}
			}
		}
	
		&:not(.dropped) .dropdown-options {
			display: none;
		}
	}

}

@media (max-width: 1125px) {

	.form-element {
		.form-element-label {
			.label {
				font-size: 11px;
			}
			.validation-msg {
				margin-top: -4px;
				font-size: 11px;
			}
		}
		.bordered-input {
			padding: 4px;
			font-size: 12px;
			letter-spacing: -0.3px;
		}
		button {
			padding: 6px 8px;
			font-size: 14px;
		}
		.checkbox-group {
			.text-column {
				font-size: 12px;
			}
		}
	}


}