@import './vars.scss';

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  overflow-y:scroll;
}

body {
  margin: 0;
  font-family: $font-1;
  background: linear-gradient(180deg, $c1 13%, $c2 43%);
  background-attachment: fixed;
}

.main {
  display: flex;
  // width: 100%;
  width: 100%;
}

.page {
  flex: 1;
  color: $c5;
  font-size: 15px;
  line-height: 24px;
  font-family: $font-2;
}

.page-content {
  // max-width: 100%;
  // min-width: 100%;
  padding: 30px;
  // width: 80%;
  margin: 24px;
  
  background-color: lighten($c2, 4);
  box-shadow: 1px 1px 4px -2px $c5;
  // border: solid 1px $c5;

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    // border-radius: 20px;
    box-shadow: 1px 1px 6px -2px $c5;
    border: solid 4px $c2;
  }

  em {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  a {
    color: darken($c3, 30);
    font-family: $font-2;
    letter-spacing: 0.5px;
    // text-decoration: underline;
    border-bottom: solid 1px darken($c3, 20);
  }

  a:hover {
    color: darken($c3, 20);
    border-bottom: solid 2px darken($c3, 20);
    // font-weight: bold;
  }

}

button {
  margin-left: 8px;
  margin-bottom: 8px;
}

.article-content img {
  display: block;
  // width: 80%;
  // margin: 10px;
  margin: 20px auto;
  box-shadow: 0px 0px 6px 0px $light-3;
  border: solid 2px $light-3;
  // object-fit: contain;
  min-width: 500px;
}

a {
  text-decoration-line: none;
  color: #5286d4;
}

.navbar p {
  margin: 0;
}

.loading-website {
  height: 100vh;
  width: 100%;
  margin-top: 100px;
  font-size: 30px;
  color: $c5;
  font-weight: bold;
  letter-spacing: 3px;
  font-family: $font-2;

  div {
    text-align: center;
  }
}

// .chartjs-render-monitor {
.chart canvas {
  // overflow: auto;
  width: 100% !important;
}

@media (max-width: 1125px) {
  .page {
    font-size: 11px;
    line-height: 18px;
  }

  .page-content {
    margin: 0;
    padding: 8px;
    
    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    
    h1 { font-size: 16px; }
    h2 { font-size: 14px; }
    h3 { font-size: 12px; }
    h4 { font-size: 11px; }
    h5 { font-size: 10px; }
    h6 { font-size: 9px; }
    
  }
  
}