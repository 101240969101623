@import './vars.scss';

.disabled#editor-js {

  .ce-toolbar { 
    display: none; 
  }
  .ce-inline-toolbar {
    display: none;
  }
  .codex-editor-overlay {
    display: none;
  }
  .codex-editor__redactor {
    padding-bottom: 20px !important;
  }


}

#editor-js::before {
  content: ' ';
  display: block;
  // position: absolute;
  width: 100%;
  height: 100%;
  border: solid 2px #8890fa;
}

// .ce-block--focused {
//   pointer-events: none;
// }
// .ce-block--focused .ce-block__content {
//   pointer-events: none;
// }
// .ce-block--focused .ce-block__content * {
//   pointer-events: auto;
// }

.ce-block__content, .ce-toolbar__content {
  max-width: 100% !important;
  // margin: 0 50px;
}


