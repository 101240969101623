
@import './vars.scss';

.navbar-ghost {
	// flex: 0;
	// min-width: $navbar-width;
	width: $navbar-width;
}

.navbar {
	position: fixed;
	font-family: $font-2;
	display: flex;
	flex-direction: column;
	bottom: 0;
	top: 0;
	left: 0px;
	max-width: $navbar-width;
	box-shadow: 5px 5px 30px 4px $c4;
	background-color: $c4;
	align-items: center;
}

.logo {
	height: 120px;
	margin-bottom: 20px;
}

.logo-background {
	// margin: 20px 0;
	margin-top: 30px;
	// padding: 0 20px;
	// padding-left: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}

.navbar-background {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	// align-items: flex-end;
	// align-items: stretch;
	justify-content: flex-start;
	flex: 1;
	margin: auto 0;
	margin-top: 10%;
	// margin-bottom: 30%;
}

.website-title {
	width: 100%;
	height: 100%;
	flex: 1;
	letter-spacing: 1.2px;
	color: $c3;
	// font-weight: 600;
	text-align: center;
	font-size: 18px;
	line-height: 24px;
	// margin-top: 16px;
	// margin-bottom: 8px;
}

.navbar-button {
	cursor: pointer;
	width: 100%;
	font-family: $font-3;
	text-align: center;
	letter-spacing: -0.5px;
	font-size: 22px;
	color: $c3;
	padding: 4px 0;

	border-radius: 40px;
	font-weight: 400;

	border-left: solid 4px rgba($c5, 0.4);
	border-right: solid 4px rgba($c5, 0.4);
	border-top: solid 4px transparent;
	border-bottom: solid 4px transparent;

	&.selected {
		background-color: $c3;
		color: $c4;
		font-weight: bold;
		border-left: solid 4px $c3;
		border-right: solid 4px $c3;
	}

	&:hover {
		width: 90%;
		border-left: solid 4px $c3;
		border-right: solid 4px $c3;
	}

	&:not(:last-child) {
		margin-bottom: 6px;
	}
}

$hover-speed: 0.24s;
$hover-easing: cubic-bezier(.85,.26,.43,.68);

.navbar {
	transition: 
		left $hover-speed $hover-easing,
		box-shadow $hover-speed $hover-easing,
		border $hover-speed $hover-easing; 
}

.navbar-background, .logo-background {
	transition: width $hover-speed $hover-easing;
}

.website-title, .navbar-button, .navbar-button.selected {
	transition: color $hover-speed $hover-easing;
}

.navbar-button {
	transition: 
		width $hover-speed $hover-easing,
		border $hover-speed $hover-easing,
		background-color 0.3s ease-out, width 0.12s ease-out;
	&.selected {
		transition:
			width $hover-speed $hover-easing,
			border $hover-speed $hover-easing, 
			background-color 0.3s ease-out, width 0.12s ease-out;
	}
}

@media screen and (max-width: 1125px), screen and (max-height: 720px) {
	
	.navbar-ghost {
		width: 220px;
	}

	.navbar {
		min-width: 220px;
		width: 220px;
	}

	.logo-background {
		margin-top: 10px;
		padding: 10px;
		flex-direction: column;

		.logo {
			height: 70px;
			margin-bottom: 10px;
		}
		.website-title {
			text-align: center;
			padding: 0;
			width: 100%;
			letter-spacing: 0.4px;
			font-size: 12px;
			line-height: 18px;
		}
	}

	.navbar-background {
		margin-top: 3%;
		// height: 50%;
		// flex: 1;
		// margin-bottom: 10px;
	}

	.navbar-button {
		padding: 2px 0;
		font-size: 15px;
		letter-spacing: 0.3px;
	}
}

@media (max-width: 800px) {
	
	.navbar-button {
		overflow: hidden;
		white-space: nowrap;
		border-left: solid 4px rgba($c5, 0.4);
		border-right: solid 4px rgba($c5, 0.4);
		border-top: solid 4px transparent;
		border-bottom: solid 4px transparent;
	}

	.page-content {
		// margin: 12px;
		// margin-left: 30px;
	}

	.navbar-ghost {
		width: 40px;
	}

	.navbar:not(:hover) {	
		.navbar-background {
			// width: 50%;
			// display: none;
			align-items: flex-end;
			width: 95%;
		}
		.website-title {
			color: transparent;
		}
		.navbar-button {
			// margin-top: 10px;
			// padding: 0;
			width: 26px;
			height: 26px;
			color: transparent;
			border: solid 4px rgba($c5, 0.4);
			margin-bottom: 9.5px;
		}
	}
	
	.navbar:not(:hover) {
		// margin: 10px;
		// border-radius: 4px;
		left: -185px;
		box-shadow: 0px 0px 15px 0px $c4;
		border-right: solid 3px $c1; 
		// min-width: 42px;
		// width: 42px;
		// height: 42px;
	}

	.navbar:hover {
		// margin: 10px;
		// border-radius: 4px;
		left: 0px;
		// min-width: 42px;
		// width: 42px;
		// height: 42px;
	}
	
}

@media screen and (max-height: 500px) {
	.logo-background .logo {
		display: none;
	}

	.navbar-button {
		padding: 3px 0;

		&:not(:last-child) {
			margin-bottom: 3px;
		}
	}

}